@layer tailwind {
  @tailwind base;
}

@tailwind components;
@tailwind utilities;

html,
body {
  background-color: #ecf2f6;
}

#root,
.app {
  height: 100%;
  width: 100%;
  @apply text-base font-work
}


.animates-slide {
  animation: slide 0.5s ease-in-out;
}

@keyframes slide {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

.truncate-two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.description-container {
  max-height: 3.0em;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.description-container.expanded {
  max-height: 1000px;
}


.hero-section-background {
  --color-start: rgba(8, 62, 129, 1);
  --color-end: rgba(16, 66, 100, 0.5);
  background:
    radial-gradient(circle at 80% 50%, rgba(18, 127, 139, 0.1), transparent 60%),
    radial-gradient(circle at 80% 50%, rgb(18, 105, 139), transparent 50%),
    linear-gradient(to right, rgb(20, 46, 78), rgba(4, 37, 60, 0.96));
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 50;
}

.hero-section-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 51;
  mix-blend-mode: normal;
  opacity: 0.2;
  background: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"><filter id="film-grain"><feTurbulence type="fractalNoise" baseFrequency="0.9" numOctaves="3" stitchTiles="stitch"/><feColorMatrix type="saturate" values="0"/><feComponentTransfer><feFuncA type="linear" slope="0.4"/></feComponentTransfer></filter><rect width="100%" height="100%" filter="url(%23film-grain)" /></svg>');
  background-size: 200px 200px;
  background-repeat: repeat;
}

.hero-section-light-background {
  --color-start: rgba(8, 62, 129, 1);
  --color-end: rgba(16, 66, 100, 0.5);
  background:
    radial-gradient(circle at 20% 20%, rgba(122, 199, 203, 0.25), transparent 20%),
    radial-gradient(circle at 80% 50%, rgb(195, 217, 226), transparent 2%),
    linear-gradient(to right, rgb(224, 229, 231), rgba(233, 237, 240, 0.96));
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 50;
}

.hero-section-light-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 51;
  mix-blend-mode: normal;
  opacity: 0.2;
  background: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"><filter id="film-grain"><feTurbulence type="fractalNoise" baseFrequency="0.9" numOctaves="3" stitchTiles="stitch"/><feColorMatrix type="saturate" values="0"/><feComponentTransfer><feFuncA type="linear" slope="0.4"/></feComponentTransfer></filter><rect width="100%" height="100%" filter="url(%23film-grain)" /></svg>');
  background-size: 200px 200px;
  background-repeat: repeat;
}

.mobile-hero-section-background {
  --color-start: rgba(8, 62, 129, 1);
  --color-end: rgba(16, 66, 100, 0.5);
  background:

    radial-gradient(circle at 50% 50%, rgb(18, 105, 139), transparent 90%),
    linear-gradient(to right, rgb(20, 46, 78), rgba(4, 37, 60, 0.96));
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 50;
}

.mobile-hero-section-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 51;
  mix-blend-mode: normal;
  opacity: 0.2;
  background: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"><filter id="film-grain"><feTurbulence type="fractalNoise" baseFrequency="0.9" numOctaves="3" stitchTiles="stitch"/><feColorMatrix type="saturate" values="0"/><feComponentTransfer><feFuncA type="linear" slope="0.4"/></feComponentTransfer></filter><rect width="100%" height="100%" filter="url(%23film-grain)" /></svg>');
  background-size: 200px 200px;
  background-repeat: repeat;
}

.mobile-hero-section-light-background {
  --color-start: rgba(8, 62, 129, 1);
  --color-end: rgba(16, 66, 100, 0.5);
  background:
    radial-gradient(circle at 20% 20%, rgba(122, 199, 203, 0.25), transparent 10%),
    radial-gradient(circle at 80% 80%, rgb(195, 217, 226, 0.3), transparent 20%),
    linear-gradient(to right, rgb(224, 229, 231), rgba(233, 237, 240, 0.96));
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 50;
}

.mobile-hero-section-light-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 51;
  mix-blend-mode: normal;
  opacity: 0.2;
  background: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"><filter id="film-grain"><feTurbulence type="fractalNoise" baseFrequency="0.9" numOctaves="3" stitchTiles="stitch"/><feColorMatrix type="saturate" values="0"/><feComponentTransfer><feFuncA type="linear" slope="0.4"/></feComponentTransfer></filter><rect width="100%" height="100%" filter="url(%23film-grain)" /></svg>');
  background-size: 200px 200px;
  background-repeat: repeat;
}

.normal-shape-background {
  --color-start: rgba(8, 62, 129, 1);
  --color-end: rgba(16, 66, 100, 0.5);
  background:
    radial-gradient(circle at 20% 20%, rgba(30, 131, 121, 0.5), transparent 60%),
    radial-gradient(circle at 80% 80%, rgba(18, 127, 139, 0.5), transparent 50%),
    linear-gradient(to right, rgba(8, 62, 129, 1), rgba(4, 37, 60, 0.96));
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 50;
}

.normal-shape-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 51;
  mix-blend-mode: normal;
  opacity: 0.2;
  background: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"><filter id="film-grain"><feTurbulence type="fractalNoise" baseFrequency="0.9" numOctaves="3" stitchTiles="stitch"/><feColorMatrix type="saturate" values="0"/><feComponentTransfer><feFuncA type="linear" slope="0.4"/></feComponentTransfer></filter><rect width="100%" height="100%" filter="url(%23film-grain)" /></svg>');
  background-size: 200px 200px;
  background-repeat: repeat;
}

.normal-shape-light-background {
  --color-start: rgba(8, 62, 129, 1);
  --color-end: rgba(16, 66, 100, 0.5);
  background:
    radial-gradient(circle at 20% 20%, rgba(189, 225, 239, 0.2), transparent 20%),
    radial-gradient(circle at 70% 60%, rgba(189, 225, 239, 0.5), transparent 30%),
    linear-gradient(to right, rgb(227, 238, 245), rgba(209, 218, 225, 0.96));
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 50;
}

.normal-shape-light-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 51;
  mix-blend-mode: normal;
  opacity: 0.2;
  background: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"><filter id="film-grain"><feTurbulence type="fractalNoise" baseFrequency="0.9" numOctaves="3" stitchTiles="stitch"/><feColorMatrix type="saturate" values="0"/><feComponentTransfer><feFuncA type="linear" slope="0.4"/></feComponentTransfer></filter><rect width="100%" height="100%" filter="url(%23film-grain)" /></svg>');
  background-size: 200px 200px;
  background-repeat: repeat;
}

.lighter-shape-background {
  --color-start: rgb(31, 90, 161);
  --color-end: rgba(38, 85, 116, 0.5);
  background:
    radial-gradient(circle at 10% 20%, rgba(49, 125, 117, 0.5), transparent 40%),
    radial-gradient(circle at 80% 80%, rgba(18, 127, 139, 0.5), transparent 50%),
    linear-gradient(to right, rgb(34, 69, 113), rgba(33, 70, 96, 0.88));
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 50;
}

.lighter-shape-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 51;
  mix-blend-mode: normal;
  opacity: 0.3;
  background: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"><filter id="film-grain"><feTurbulence type="fractalNoise" baseFrequency="0.9" numOctaves="3" stitchTiles="stitch"/><feColorMatrix type="saturate" values="0"/><feComponentTransfer><feFuncA type="linear" slope="0.4"/></feComponentTransfer></filter><rect width="100%" height="100%" filter="url(%23film-grain)" /></svg>');
  background-size: 200px 200px;
  background-repeat: repeat;
}

.lighter-shape-light-background {
  --color-start: rgb(31, 90, 161);
  --color-end: rgba(38, 85, 116, 0.5);
  background:
    radial-gradient(circle at 80% 50%, rgba(173, 202, 204, 0.1), transparent 40%),
    linear-gradient(to right, rgb(236, 242, 246), rgba(219, 228, 235, 0.66));
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 50;
}

.lighter-shape-light-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 51;
  mix-blend-mode: normal;
  opacity: 0.3;
  background: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"><filter id="film-grain"><feTurbulence type="fractalNoise" baseFrequency="0.9" numOctaves="3" stitchTiles="stitch"/><feColorMatrix type="saturate" values="0"/><feComponentTransfer><feFuncA type="linear" slope="0.4"/></feComponentTransfer></filter><rect width="100%" height="100%" filter="url(%23film-grain)" /></svg>');
  background-size: 200px 200px;
  background-repeat: repeat;
}

.tall-shape-background {
  --color-start: rgba(8, 62, 129, 1);
  --color-end: rgba(16, 66, 100, 0.5);
  background:
    radial-gradient(circle at 20% 20%, rgba(30, 131, 121, 0.5), transparent 60%),
    radial-gradient(circle at 40% 50%, rgba(34, 116, 175, 0.5), transparent 50%),
    radial-gradient(circle at 80% 80%, rgba(18, 127, 139, 0.5), transparent 50%),
    linear-gradient(to right, rgba(8, 62, 129, 1), rgba(4, 37, 60, 0.96));
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 50;
}

.tall-shape-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 51;
  mix-blend-mode: normal;
  opacity: 0.3;
  background: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"><filter id="film-grain"><feTurbulence type="fractalNoise" baseFrequency="0.9" numOctaves="3" stitchTiles="stitch"/><feColorMatrix type="saturate" values="0"/><feComponentTransfer><feFuncA type="linear" slope="0.4"/></feComponentTransfer></filter><rect width="100%" height="100%" filter="url(%23film-grain)" /></svg>');
  background-size: 200px 200px;
  background-repeat: repeat;
}

.tall-shape-light-background {
  --color-start: rgba(8, 62, 129, 1);
  --color-end: rgba(16, 66, 100, 0.5);
  background:
  radial-gradient(circle at 80% 10%, rgba(173, 202, 204, 0.2), transparent 20%),
    radial-gradient(circle at 10% 50%, rgba(173, 202, 204, 0.2), transparent 40%),
    linear-gradient(to right, rgb(231, 237, 240), rgb(235, 238, 240));
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 50;
}

.tall-shape-light-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 51;
  mix-blend-mode: normal;
  opacity: 0.3;
  background: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"><filter id="film-grain"><feTurbulence type="fractalNoise" baseFrequency="0.9" numOctaves="3" stitchTiles="stitch"/><feColorMatrix type="saturate" values="0"/><feComponentTransfer><feFuncA type="linear" slope="0.4"/></feComponentTransfer></filter><rect width="100%" height="100%" filter="url(%23film-grain)" /></svg>');
  background-size: 200px 200px;
  background-repeat: repeat;
}

.hovercard-background {
  --color-start: rgb(31, 90, 161);
  --color-end: rgba(38, 85, 116, 0.5);
  background:
    radial-gradient(circle at 20% 20%, rgba(30, 131, 121, 0.5), transparent 60%),
    radial-gradient(circle at 40% 50%, rgba(34, 116, 175, 0.5), transparent 50%),
    radial-gradient(circle at 80% 80%, rgba(18, 127, 139, 0.5), transparent 90%),
    linear-gradient(to right, rgba(8, 62, 129, 0.95), rgba(7, 61, 100, 0.96));
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  overflow: visible;
  z-index: 50;
}

.hovercard-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;

  pointer-events: none;
  z-index: 51;
  mix-blend-mode: normal;
  opacity: 0.3;
  background: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"><filter id="film-grain"><feTurbulence type="fractalNoise" baseFrequency="0.9" numOctaves="3" stitchTiles="stitch"/><feColorMatrix type="saturate" values="0"/><feComponentTransfer><feFuncA type="linear" slope="0.4"/></feComponentTransfer></filter><rect width="100%" height="100%" filter="url(%23film-grain)" /></svg>');
  background-size: 200px 200px;
  background-repeat: repeat;
}

.transparent-container {
  border: 1px solid #b6cbda30;
  background-color: #6f859510;
}

.mantine-Select-option:hover {
  background-color: #abbaca50 !important;
}